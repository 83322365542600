<template>
  <v-layout row justify-end>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <template v-slot:activator="{ on }">
        <v-btn flat round color="white" class="btn btn-primary px-4" large @click="openModal">
          <i class="fal fa-plus mr-3"></i>
          {{ modalTitle }}
        </v-btn>
      </template>
      <v-card class="modal-full-screen filter">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list three-line subheader style="padding:64px 0 0 0">
          <v-layout row wrap>
            <v-flex id="dropdown-example">
              <v-layout row>
                <v-flex father-between>
                  <div class="pa-4">
                    <v-layout filter-modal-add-project>
                      <v-flex lg10 sm8 xs7 d-flex align-center pr-3>
                        <InputText
                          type="text"
                          textLabel="Pesquisar"
                          v-model="projectNameFilter"
                          @input="search()"
                        />
                      </v-flex>
                      <v-flex lg2 sm4 xs5>
                        <InputSelect
                          textLabel="Instituição"
                          :items="institutionListFiltered"
                          valueAttribute="id"
                          textAttribute="entity.name"
                          v-model="institutionFilter"
                          @input="search()"
                        ></InputSelect>
                      </v-flex>
                      <!--<v-flex align-end d-flex>
                        <v-btn
                          flat
                          round
                          color="white"
                          class="d-flex btn btn-primary px-5 mb-2"
                          large
                          @click="search()"
                        >Pesquisar</v-btn>
                      </v-flex>-->
                    </v-layout>
                    <div class="list-scroll m-height-170 mt-3 modal-full-content">
                      <!--item/projeto-->
                      <div
                        class="check-project"
                        v-for="project in projectListFiltered"
                        :key="project.id"
                      >
                        <ProjectBox :project="project" :institutionList="institutionList"></ProjectBox>
                        <div class="px-4">
                          <v-checkbox
                            v-model="selectedProjects"
                            :key="project.id"
                            :value="project.id"
                            @change="checkboxChanged()"
                          ></v-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-flex class="align-center justify-end footer-step-modal full-screen">
                    <v-btn
                      flat
                      round
                      color="white"
                      class="d-flex btn btn-default px-5"
                      large
                      @click="dialog = false"
                    >Cancelar</v-btn>
                    <v-btn
                      flat
                      round
                      color="white"
                      class="d-flex btn btn-primary px-5"
                      large
                      @click="save()"
                    >Concluir</v-btn>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-list>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import ProjectBox from "@/components/ProjectBox.vue";
import AucAlert from "@/components/AucAlert.vue";
import ProjectService from "@/scripts/services/project.service.js";
import { CampaignTypes } from "@/scripts/models/enums/campaignTypes.enum";
import { ProjectTypes } from "../scripts/models/enums/projectTypes.enum";


export default {
  components: {
    AucAlert,
    ProjectBox
  },
  props: ["institutionList", "value", "campaignTypeId"],
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      projectService: new ProjectService(),

      institutionFilter: null,
      projectNameFilter: "",
      projectListFiltered: [],
      institutionListFiltered: [],
      projectList: [],
      selectedProjects: [],
      selectedCompleteProjects: [],
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.selectedCompleteProjects = this.value;
        this.selectedProjects = [];
        for (let i = 0; i < this.selectedCompleteProjects.length; i++) {
          this.selectedProjects.push(this.selectedCompleteProjects[i].id);
        }

        this.clearFilters();
        this.search();
      }
    }
  },
  computed: {
    modalTitle() {
      return this.campaignTypeId == CampaignTypes.Voluntariado ? "Adicionar Projeto de Voluntariado" : "Adicionar Projeto";
    },
    isVolunteering() {
      return this.campaignTypeId == CampaignTypes.Voluntariado;
    }
  },
  created() {
     this.projectService.listAllProjectsWithCaptureStatusOn().then(this.loadProjects);
     this.search();
  },
  methods: {
    openModal() {
      this.$emit('checkCampaignType');
      this.dialog = this.campaignTypeId != 0;
    },
    search() {
      if (this.isVolunteering) {
        this.institutionListFiltered = this.institutionList.filter(institution => this.projectList.some(volunteering => volunteering.institutionId == institution.id));
        this.projectListFiltered = this.projectListFiltered.filter(
          project =>
            (this.institutionFilter == 0 || project.institutionId == this.institutionFilter) &&
            (this.projectNameFilter == null || this.projectNameFilter == "" || project.shortName.toLowerCase().includes(this.projectNameFilter)));
      }
      else {
        this.institutionListFiltered = this.institutionList;
        this.projectListFiltered = this.projectList.filter(
          project =>
            (this.institutionFilter == 0 || project.institutionId == this.institutionFilter) &&
            (this.projectNameFilter == null || this.projectNameFilter == "" || project.shortName.toLowerCase().includes(this.projectNameFilter)));
      }
    },
    refreshList(campaignType) {
      this.projectService
        .listAllProjectsWithCaptureStatusOn(campaignType)
        .then(this.loadProjects);
      this.search();
    },
    clearFilters() {
      this.projectNameFilter = "";
      this.institutionFilter = 0;
    },
    loadProjects(data) {
      this.projectList = data;
      this.projectListFiltered = data;
    },
    save() {
      this.$emit("input", this.selectedCompleteProjects);
      this.dialog = false;
    },
    checkboxChanged2() {
      let newSelectedProjects = [];
      let newSelectedCompleteProjects = [];

      let found = this.selectedProjects.some(project =>
        this.selectedCompleteProjects.some(completeProject => project == completeProject.id));

      if (!found) {
        newSelectedProjects = this.projectListFiltered.filter(projectFiltered =>
          this.selectedProjects.some(project => projectFiltered.id == project));
      }

      newSelectedCompleteProjects = this.selectedCompleteProjects.filter(selectedCompleteProject =>
        this.selectedProjects.some(selectedProject => selectedProject == selectedCompleteProject.id))


      this.selectedCompleteProjects = newSelectedProjects.concat(newSelectedCompleteProjects);
    },
    checkboxChanged() {
      let newSelectedCompleteProjects = [];
      for (const selectedProject of this.selectedProjects) {
        
        let found = this.selectedCompleteProjects.some(selectedCompleteProject => 
          selectedCompleteProject.id == selectedProject);       

        if(!found){
          newSelectedCompleteProjects = this.projectList.filter(project => 
            project.id == selectedProject);
        }
      }

      for (const selectedCompleteProject of this.selectedCompleteProjects) {
        let found = this.selectedProjects.some(selectedProject => 
          selectedCompleteProject.id == selectedProject);
        
        if(found){
          newSelectedCompleteProjects.push(selectedCompleteProject);
        }
      }
      this.selectedCompleteProjects = newSelectedCompleteProjects;
    }
  }
};
</script>
<style>
</style>